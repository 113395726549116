import React, { useCallback, useMemo } from 'react';
import { useGetSourceStorePickingIssuesQuery } from '../../../apiCalculationPickingResults';
import LoadingIndicator from '../../../../../../components/global/LoadingIndicator/LoadingIndicator';
import { getSourceStorePickingIssuesColumns } from '../pickingIssuesLib';
import { SimpleTable } from '../../../../Statistics/components/SimpleTable';
import { tableScroll } from '@ydistri/ds';
import { SourceStorePickingIssuesResponse } from '@ydistri/api-sdk';
import EmbeddedTableWrapper from '../../../components/EmbeddedTableWrapper';
import { ApplicationDataType } from '@ydistri/utils';
import { useApplicationData } from '../../../../../../hooks/useApplicationData';

interface SourceStorePickingIssuesTableProps {
  calculationId: number;
  sourceStoreId: number;
}

const SourceStorePickingIssuesTable: React.FC<SourceStorePickingIssuesTableProps> = ({
  calculationId,
  sourceStoreId,
}) => {
  const { [ApplicationDataType.STORE_CODE]: hasStoreCodes } = useApplicationData();
  const { data, isFetching } = useGetSourceStorePickingIssuesQuery({
    calculationId,
    sourceStoreId,
  });

  const tableColumns = useMemo(() => {
    return getSourceStorePickingIssuesColumns(hasStoreCodes);
  }, [hasStoreCodes]);

  const rowKeyProvider = useCallback(
    (record: SourceStorePickingIssuesResponse) => `${record.sourceSkuId}-${record.targetSkuId}`,
    [],
  );

  if (isFetching) {
    return <LoadingIndicator />;
  }

  if (data) {
    return (
      <EmbeddedTableWrapper title="Issues">
        <SimpleTable<SourceStorePickingIssuesResponse>
          id="store-picking-issues"
          scroll={tableScroll}
          dataSource={data}
          columns={tableColumns}
          loading={isFetching}
          showHeader={true}
          rowKey={rowKeyProvider}
          showBorder={true}
        />
      </EmbeddedTableWrapper>
    );
  }
};

export default SourceStorePickingIssuesTable;
