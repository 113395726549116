import React from 'react';
import { CategoryTreeNode } from '../../../../../components/global/CategoryTree/apiCategoryTree';
import { EntityId, Row, Text } from '@ydistri/ds';
import SimpleList from './SimpleList';
import YLink from '../../../../../components/global/Link/YLink';
import { generatePath, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../../components/menu/menuLeftItemTemplate';
import LinkIcon from '../../../../../components/global/Link/LinkIcon';

interface CategoriesListProps {
  categories: CategoryTreeNode[];
}

/**
 * List of categories with their customer category id
 * @param categories
 * @constructor
 */
const CategoriesList: React.FC<CategoriesListProps> = ({ categories }) => {
  const { projectShortName } = useParams();

  return (
    <SimpleList
      values={categories.map(category => {
        const path = generatePath(ROUTES.detail, {
          projectShortName: projectShortName ?? '',
          slug: category.slug,
        });

        return (
          <Row $gap="0.6rem" $alignItems="center" key={category.id}>
            <YLink to={path}>
              <Row $gap="0.6rem" $alignItems="center">
                <Text>{category.title}</Text>
                <LinkIcon url={path} />
              </Row>
            </YLink>
            {category.customerCategoryId && (
              <EntityId id={category.customerCategoryId} code={category.code} />
            )}
          </Row>
        );
      })}
    />
  );
};

export default CategoriesList;
