import {
  CalculationPickingInsightsSummaryResponse,
  CalculationPickingIssuesResponse,
  CalculationStatisticClusterType,
  EvaluationRatesResponse,
  RoutesEvaluationRatesResponse,
  SourceStorePickingIssuesResponse,
  SourceStorePickingResultResponse,
  StorePickingInsightsResponse,
  TargetStorePickingResultResponse,
} from '@ydistri/api-sdk';
import { getTags } from '../../../apis/apiLib';
import { apiSlice } from '../../../apis/api';
import { CalculationsCollection } from '../../../swagger/collections';
import { computePercentage } from '@ydistri/utils';
import {
  CalculationPickingExtendedResponse,
  PickingResultsExtended,
  SourceStorePickingResultExtendedResponse,
} from './pickingResultsLib';

const { TAGS, TAGS_ARRAY } = getTags('calculationPickingResults');

export interface GetPickingResultsRequest {
  calculationId: number;
  departmentId?: number;
  clusterType?: CalculationStatisticClusterType;
}

export interface GetTargetStoresPickingDetailsRequest extends GetPickingResultsRequest {
  sourceStoreId: number;
}

export interface GetPickingResultsSummaryRequest {
  calculationId: number;
}

export const computePickingResultsPercentages = (
  response: RoutesEvaluationRatesResponse | EvaluationRatesResponse,
): PickingResultsExtended => {
  return {
    ...response,
    executedQuantityPercentage: computePercentage(
      response.executedQuantity,
      response.totalQuantity,
    ),
    executedValuePercentage: computePercentage(response.executedValue, response.totalValue),
    executedSkuCountPercentage: computePercentage(
      response.executedSkuCount,
      response.totalSkuCount,
    ),
  };
};

export const extendStoreStatistics = (
  pickingResultsResponse: SourceStorePickingResultResponse[] | TargetStorePickingResultResponse[],
): SourceStorePickingResultExtendedResponse[] => {
  return pickingResultsResponse.map(response => {
    return {
      ...response,
      pickingResults: computePickingResultsPercentages(response.pickingResults),
    };
  });
};

export const apiCalculationPickingResults = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getPickingResultsSummary: builder.query<
        CalculationPickingExtendedResponse,
        GetPickingResultsSummaryRequest
      >({
        queryFn: async ({ calculationId }) => {
          const { data } = await CalculationsCollection.getCalculationPicking(calculationId);

          const result: CalculationPickingExtendedResponse = {
            ...data.data,
            pickingResults: computePickingResultsPercentages(data.data.pickingResults),
          };

          return {
            data: result,
          };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationPickingResultsSummary,
            id: `pickingSummary-${arg.calculationId}`,
          },
        ],
      }),
      getStoresPickingResults: builder.query<
        SourceStorePickingResultExtendedResponse[],
        GetPickingResultsSummaryRequest
      >({
        queryFn: async ({ calculationId }) => {
          const { data } =
            await CalculationsCollection.getCalculationPickingSourceStores(calculationId);

          const result: SourceStorePickingResultExtendedResponse[] = extendStoreStatistics(
            data.data,
          );

          return {
            data: result,
          };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationPickingResultsSummary,
            id: `storePickingResults-${arg.calculationId}`,
          },
        ],
      }),
      getTargetStoresPickingResults: builder.query<
        SourceStorePickingResultExtendedResponse[],
        GetTargetStoresPickingDetailsRequest
      >({
        queryFn: async ({ calculationId, sourceStoreId }) => {
          const { data } = await CalculationsCollection.getCalculationPickingTargetStores(
            calculationId,
            sourceStoreId,
          );

          const result: SourceStorePickingResultExtendedResponse[] = extendStoreStatistics(
            data.data,
          );

          return {
            data: result,
          };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationStoreTargetStoresPickingResults,
            id: `targetStores-${arg.calculationId}-${arg.sourceStoreId}`,
          },
        ],
      }),
      getPickingInsights: builder.query<StorePickingInsightsResponse[], number>({
        queryFn: async (calculationId: number) => {
          const response =
            await CalculationsCollection.getCalculationPickingInsights(calculationId);
          return { data: response.data.data };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationPickingResultsSummary,
            id: `pickingInsights-${arg}`,
          },
        ],
      }),
      getTargetStoresPickingInsights: builder.query<
        StorePickingInsightsResponse[],
        GetTargetStoresPickingDetailsRequest
      >({
        queryFn: async ({ calculationId, sourceStoreId }) => {
          const response = await CalculationsCollection.getSourceStorePickingInsights(
            calculationId,
            sourceStoreId,
          );
          return { data: response.data.data };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationStoreTargetStorePickingInsights,
            id: `targetStoresPickingInsights-${arg.calculationId}-${arg.sourceStoreId}`,
          },
        ],
      }),
      getPickingInsightsSummary: builder.query<
        CalculationPickingInsightsSummaryResponse,
        GetPickingResultsSummaryRequest
      >({
        queryFn: async ({ calculationId }) => {
          const response =
            await CalculationsCollection.getCalculationPickingInsightsSummary(calculationId);
          return { data: response.data.data };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationPickingInsightsSummary,
            id: `pickingInsightsSummary-${arg.calculationId}`,
          },
        ],
      }),
      getPickingIssues: builder.query<CalculationPickingIssuesResponse[], number>({
        queryFn: async (calculationId: number) => {
          const response = await CalculationsCollection.getCalculationPickingIssues(calculationId);
          return { data: response.data.data };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationPickingIssues,
            id: `pickingIssues-${arg}`,
          },
        ],
      }),
      getSourceStorePickingIssues: builder.query<
        SourceStorePickingIssuesResponse[],
        GetTargetStoresPickingDetailsRequest
      >({
        queryFn: async ({ calculationId, sourceStoreId }) => {
          const response = await CalculationsCollection.getSkusWithIssues(
            calculationId,
            sourceStoreId,
          );
          return { data: response.data.data };
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.calculationSourceStorePickingIssues,
            id: `sourceStorePickingIssues-${arg.calculationId}-${arg.sourceStoreId}`,
          },
        ],
      }),
    }),
  });

export const {
  useGetPickingResultsSummaryQuery,
  useGetStoresPickingResultsQuery,
  useGetTargetStoresPickingResultsQuery,
  useGetPickingInsightsQuery,
  useGetTargetStoresPickingInsightsQuery,
  useGetPickingInsightsSummaryQuery,
  useGetPickingIssuesQuery,
  useGetSourceStorePickingIssuesQuery,
} = apiCalculationPickingResults;
