import {
  ApiOperationType,
  CalculationResponse,
  EntityListCreateRequest,
  EntityListImportAction,
  EntityListImportItemResponse,
  EntityListImportItemsRequest,
  EntityListImportRequest,
  EntityListImportResponse,
  EntityListImportSummaryResponse,
  EntityListImportVerificationRequest,
  EntityListItemResponse,
  EntityListResponse,
  EntityListType,
  LogicalOperator,
  Operation,
  SortDirection,
} from '@ydistri/api-sdk';
import { apiSlice, ErrorType } from '../../../apis/api';
import { EntityListsCollection } from '../../../swagger/collections';
import { getTags } from '../../../apis/apiLib';
import {
  forceRefetchForInfiniteScroll,
  InfiniteScrollParams,
  mergeForInfiniteScroll,
  serializeQueryArgsForInfiniteScroll,
} from '@ydistri/ds';
import { SignalProjectConfigurationChanged } from '../common/administrationItemsTypes';
import { ReduxState } from '../../../store';
import { addToast } from '../../../../../../../libs/utils/src/lib/toastSlice';
import { SignalConfigurationChanged } from '../../../signalr/signalrInterfaces';
import { signalrClient } from '../../../signalr/client';
import { setSelectedEntityList } from './entityListAdministrationSlice';
import { titleByEntityListType } from './entityListsLib';

const { TAGS, TAGS_ARRAY } = getTags('entityListsAdministration');

export type EntityListUpdateRequest = Pick<
  EntityListResponse,
  'entityListId' | 'entityListTypeId'
> &
  Partial<Pick<EntityListResponse, 'name' | 'description' | 'isArchived'>>;

export interface GetEntityListRequest {
  entityListType: EntityListType;
  entityListId: number;
}
export interface GetLatestEntityListItemsRequest extends InfiniteScrollParams {
  entityListId: number;
}

export interface GetEntityListImportItemsRequest {
  entityListId: number;
  entityListImportAction: EntityListImportAction;
}

export interface CreateEntityListImportRequest extends EntityListImportRequest {
  entityListType: EntityListType;
  entityListId: number;
}

export interface PutEntityListImportItemsRequest extends EntityListImportItemsRequest {
  entityListId: number;
}

export interface PutEntityListItemsRequest extends EntityListImportVerificationRequest {
  entityListId: number;
}

export interface DeleteEntityListImportRequest extends EntityListImportVerificationRequest {
  entityListType: EntityListType;
  entityListId: number;
}

export interface DeleteEntityListRequest {
  entityListType: EntityListType;
  entityListId: number;
}

export const apiEntityLists = apiSlice
  .enhanceEndpoints({
    addTagTypes: TAGS_ARRAY,
  })
  .injectEndpoints({
    endpoints: builder => ({
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      getEntityList: builder.query<EntityListResponse | undefined, GetEntityListRequest>({
        queryFn: async (arg, { dispatch }) => {
          const data = await EntityListsCollection.entityListsList({
            conditions: [
              {
                fieldName: 'EntityListId',
                operation: Operation.Eq,
                value: arg.entityListId,
              },
              {
                fieldName: 'EntityListTypeId',
                operation: Operation.Eq,
                value: arg.entityListType,
                logicalOperator: LogicalOperator.And,
              },
            ],
          });

          if (data.data.data.length === 1) {
            const entityListData = data.data.data[0];

            dispatch(
              apiEntityLists.util.updateQueryData('getEntityLists', arg.entityListType, draft => {
                draft.forEach(item => {
                  if (item.entityListId === entityListData.entityListId) {
                    Object.assign(item, entityListData);
                  }
                });
              }),
            );

            return { data: data.data.data[0] };
          }
          return { data: undefined };
        },
        providesTags: (result, error, arg) => [{ type: TAGS.entityList, id: arg.entityListId }],
      }),

      getEntityLists: builder.query<EntityListResponse[], EntityListType>({
        queryFn: async arg => {
          const data = await EntityListsCollection.entityListsList({
            conditions: [
              {
                fieldName: 'EntityListTypeId',
                operation: Operation.Eq,
                value: arg,
              },
            ],
          });

          return data.data;
        },
        providesTags: (result, error, arg) => [{ type: TAGS.entityListsList, id: arg }],
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState, dispatch },
        ) {
          const signalerListener = (signal: SignalProjectConfigurationChanged) => {
            console.log({ signal });
            if (signal.entityType !== 'EntityList') return;
            const entityListId = signal.entityId;

            dispatch(
              apiEntityLists.util.invalidateTags([{ type: TAGS.entityList, id: entityListId }]),
            );

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const state = getState() as ReduxState;

            //if the currently selected target list was deleted,
            //we need to clear the selected target list
            const selectedEntityList = state.entityListsAdministration[arg].selectedEntityList;
            if (selectedEntityList && signal.entityId === selectedEntityList.entityListId) {
              if (signal.operationType === ApiOperationType.Delete) {
                dispatch(setSelectedEntityList({ entityListType: arg, data: undefined }));
                dispatch(
                  addToast({
                    message: `${titleByEntityListType[arg]} has been deleted`,
                  }),
                );
              }
            }

            if (signal.operationType === ApiOperationType.Create) {
              EntityListsCollection.entityListsList({
                conditions: [
                  {
                    fieldName: 'EntityListId',
                    operation: Operation.Eq,
                    value: entityListId,
                  },
                ],
              }).then(newEntityListData => {
                const {
                  data: { data: newEntityListArray },
                } = newEntityListData;
                if (newEntityListArray.length === 1) {
                  dispatch(
                    apiEntityLists.util.updateQueryData('getEntityLists', arg, draft => {
                      draft.push(newEntityListArray[0]);
                    }),
                  );
                }
              });
            } else if (signal.operationType === ApiOperationType.Delete) {
              dispatch(
                apiEntityLists.util.updateQueryData('getEntityLists', arg, draft => {
                  return draft.filter(e => e.entityListId !== entityListId);
                }),
              );
            } else {
              console.log('Other operation type');
            }
          };

          /**
           * React to changes in the template configuration when target list is set to or removed from a template.
           * Based on this change the target list might be marked as archivable or not and list of templates
           * where it is used will change.
           * The listener fetches the target list from the server and updates the cache.
           * @param signal
           */
          const templateConfigurationListener = (signal: SignalConfigurationChanged) => {
            console.log('templateConfigurationListener', signal);
            if (signal.entityType === 'EntityListConfiguration') {
              const entityListId = signal.entityId;
              EntityListsCollection.entityListsList({
                conditions: [
                  {
                    fieldName: 'EntityListId',
                    operation: Operation.Eq,
                    value: entityListId,
                  },
                ],
              })
                .then(response => {
                  try {
                    dispatch(
                      apiEntityLists.util.invalidateTags([
                        { type: TAGS.entityListCalculations, id: entityListId },
                      ]),
                    );

                    const tmpEntityList = response.data.data[0];
                    updateCachedData(data => {
                      const entityListIndex = data.findIndex(
                        tl => tl.entityListId === tmpEntityList.entityListId,
                      );
                      if (entityListIndex !== -1) {
                        data[entityListIndex] = tmpEntityList;
                        return data;
                      }
                    });
                  } catch (error) {
                    dispatch(
                      addToast({
                        message: `Failed to update the ${titleByEntityListType[arg]}, refresh the page.`,
                        isError: true,
                      }),
                    );
                  }
                })
                .catch((error: ErrorType) => {
                  dispatch(
                    addToast({
                      message: `Failed to load ${titleByEntityListType[arg]}: ${error.response.data.Messages.join(
                        ', ',
                      )}`,
                      isError: true,
                    }),
                  );
                });
            }
          };

          try {
            await cacheDataLoaded;
            signalrClient.on('ProjectConfigurationChanged', signalerListener);
            signalrClient.on('TemplateConfigurationChanged', templateConfigurationListener);

            cacheEntryRemoved.then(() => {
              signalrClient.off('ProjectConfigurationChanged', signalerListener);
              signalrClient.off('TemplateConfigurationChanged', templateConfigurationListener);
            });
            await cacheEntryRemoved;
          } catch (error) {
            console.error(error);
          }
        },
      }),

      createEntityList: builder.mutation<EntityListResponse, EntityListCreateRequest>({
        queryFn: async payload => {
          try {
            const response = await EntityListsCollection.createEntityList({
              name: payload.name,
              description: payload.description,
              entityListTypeId: payload.entityListTypeId,
            });
            const newEntityList = response.data.data;

            return { data: newEntityList };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: err };
          }
        },
      }),

      createEntityListImport: builder.mutation<
        EntityListImportResponse,
        CreateEntityListImportRequest
      >({
        queryFn: async ({ entityListId, entityListType, ...payload }, { dispatch }) => {
          try {
            const response = await EntityListsCollection.createEntityListImport(entityListId, {
              ...payload,
            });
            const data = response.data.data;

            dispatch(
              apiEntityLists.util.updateQueryData('getEntityLists', entityListType, draft =>
                draft.map(el =>
                  el.entityListId === entityListId ? { ...el, entityListImport: data } : el,
                ),
              ),
            );
            dispatch(
              apiEntityLists.util.updateQueryData(
                'getEntityList',
                { entityListType, entityListId },
                draft => {
                  if (draft) draft.entityListImport = data;
                },
              ),
            );

            return { data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: err };
          }
        },
        // invalidatesTags: [TAGS.entityListsList],
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      deleteEntityListImport: builder.mutation<void, DeleteEntityListImportRequest>({
        queryFn: async ({ entityListId, entityListType, ...payload }, { dispatch }) => {
          try {
            const { data } = await EntityListsCollection.deleteEntityListImport(entityListId, {
              ...payload,
            });

            dispatch(
              apiEntityLists.util.updateQueryData('getEntityLists', entityListType, draft =>
                draft.map(el =>
                  el.entityListId === entityListId ? { ...el, entityListImport: null } : el,
                ),
              ),
            );
            dispatch(
              apiEntityLists.util.updateQueryData(
                'getEntityList',
                { entityListType, entityListId },
                draft => {
                  if (draft) draft.entityListImport = null;
                },
              ),
            );

            return { data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: err };
          }
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      putEntityListImportItems: builder.mutation<void, PutEntityListImportItemsRequest>({
        queryFn: async ({ entityListId, ...payload }) => {
          try {
            const { data } = await EntityListsCollection.putEntityListImportItems(entityListId, {
              ...payload,
            });

            return { data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: err };
          }
        },
        invalidatesTags: [TAGS.entityListImportSummary],
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      putEntityListItems: builder.mutation<void, PutEntityListItemsRequest>({
        queryFn: async ({ entityListId, ...payload }) => {
          try {
            const { data } = await EntityListsCollection.putEntityListItems(entityListId, {
              ...payload,
            });

            return { data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: err };
          }
        },
        invalidatesTags: (result, error, arg) => [
          TAGS.entityListsList,
          TAGS.entityListItems,
          { type: TAGS.entityList, id: arg.entityListId },
        ],
      }),

      deleteEntityList: builder.mutation<number, DeleteEntityListRequest>({
        queryFn: async ({ entityListId, entityListType }, { dispatch }) => {
          await EntityListsCollection.deleteEntityList(entityListId);

          dispatch(
            apiEntityLists.util.updateQueryData('getEntityLists', entityListType, draft => {
              return draft.filter(entityList => entityList.entityListId !== entityListId);
            }),
          );

          return { data: entityListId };
        },
      }),

      updateEntityList: builder.mutation<EntityListResponse | undefined, EntityListUpdateRequest>({
        queryFn: async (request: EntityListUpdateRequest) => {
          let result: EntityListResponse | undefined = undefined;

          const response = await EntityListsCollection.updateEntityList(request.entityListId, {
            ...request,
          });
          result = response.data.data;

          return { data: result };
        },
      }),

      getEntityListItems: builder.query<EntityListItemResponse[], GetLatestEntityListItemsRequest>({
        queryFn: async args => {
          const query = {
            top: args.top,
            skip: args.skip,
            inlineCount: true,
            sortings: args.sortings,
            conditions: args.conditions,
            search: args.search,
          };

          const response = await EntityListsCollection.getLatestEntityListItems(
            args.entityListId,
            query,
          );
          return { data: response.data.data };
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.entityListItems, id: arg.entityListId },
        ],
        serializeQueryArgs: serializeQueryArgsForInfiniteScroll<GetLatestEntityListItemsRequest>(),
        merge: mergeForInfiniteScroll<EntityListItemResponse, InfiniteScrollParams>(),
        forceRefetch: forceRefetchForInfiniteScroll<InfiniteScrollParams | undefined>(),
      }),

      getEntityListCalculations: builder.query<CalculationResponse[], number>({
        queryFn: async entityListId => {
          const response = await EntityListsCollection.getEntityListCalculations(entityListId, {
            sortings: [
              {
                fieldName: 'Title',
                direction: SortDirection.Asc,
              },
            ],
          });

          return { data: response.data.data };
        },
        providesTags: (result, error, arg) => [{ type: TAGS.entityListCalculations, id: arg }],
      }),

      getEntityListImportSummary: builder.query<EntityListImportSummaryResponse[], number>({
        queryFn: async entityListId => {
          const response = await EntityListsCollection.getEntityListImportSummary(entityListId);

          return { data: response.data.data };
        },
        providesTags: (result, error, arg) => [{ type: TAGS.entityListImportSummary, id: arg }],
      }),

      getEntityListImportItems: builder.query<
        EntityListImportItemResponse[],
        GetEntityListImportItemsRequest
      >({
        queryFn: async arg => {
          const query = {
            top: 500,
            skip: 0,
            inlineCount: true,
            conditions: [
              {
                fieldName: 'EntityListImportActionCode',
                operation: Operation.Eq,
                value: arg.entityListImportAction,
              },
            ],
          };

          const response = await EntityListsCollection.getEntityListImportItems(
            arg.entityListId,
            query,
          );

          return { data: response.data.data };
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        providesTags: (result, error, arg) => [
          {
            type: TAGS.entityListImportItems,
            id: `${arg.entityListId}-${arg.entityListImportAction}`,
          },
        ],
      }),
    }),
  });

export const {
  useGetEntityListsQuery,

  useGetEntityListQuery,
  useUpdateEntityListMutation,
  useCreateEntityListMutation,
  useDeleteEntityListMutation,

  useGetEntityListItemsQuery,
  usePutEntityListItemsMutation,

  useGetEntityListCalculationsQuery,

  useGetEntityListImportSummaryQuery,

  useCreateEntityListImportMutation,
  useDeleteEntityListImportMutation,

  useGetEntityListImportItemsQuery,
  usePutEntityListImportItemsMutation,
} = apiEntityLists;
