import { useDispatch } from 'react-redux';
import { Column, Row } from '@ydistri/ds';
import DetailContentHeaderSection from '../DetailContentHeaderSection/DetailContentHeaderSection';
import React, { useEffect } from 'react';
import { SkusCollection } from '../../../../swagger/collections';
import { parseSkuAttributes } from '../../../../lib/sku/skuLib';
import { setIsLoadingSalesData, setSkuEntity, setSkuId, skuNotFound } from '../../detailSlice';
import { getSku, makeSKUAttributesStorable } from '../../detailLib';
import { addToast } from '../../../../../../../../libs/utils/src/lib/toastSlice';
import DetailContentBodySection from '../DetailContentBodySection/DetailContentBodySection';
import useCategoryId from '../../../../hooks/useCategoryId';
import { useDetail } from '../../hooks/useDetail';
import { NO_VALUE } from '../../../../lib/utils/utilsTypes';

const DetailContent: React.FC = () => {
  const { productId, storeId, skuId } = useDetail('productId', 'storeId', 'skuId');
  const dispatch = useDispatch();
  const selectedCategoryId = useCategoryId();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (productId !== NO_VALUE && storeId !== NO_VALUE) {
      getSku(productId, storeId, signal)
        .then(sku => {
          if (sku) {
            if (skuId !== sku.skuId) {
              SkusCollection.getSkuAttributes(sku.skuId)
                .then(({ data }) => {
                  const attributes = parseSkuAttributes(sku.skuId, data.data);
                  dispatch(setSkuEntity(makeSKUAttributesStorable(attributes)));
                  dispatch(setSkuId(sku.skuId));
                })
                .catch(() => {
                  dispatch(addToast({ message: 'SKU was not found', isError: true }));
                });
            }
          } else {
            dispatch(skuNotFound());
          }
        })
        .finally(() => {
          dispatch(setIsLoadingSalesData(false));
        });
    }

    return () => {
      controller.abort();
    };
  }, [dispatch, productId, storeId, skuId]);

  useEffect(() => {
    dispatch(setIsLoadingSalesData(true));
  }, [dispatch, selectedCategoryId]);

  return (
    <Row $flexGrow={1} data-testid="DetailContent" $flexWrap="nowrap">
      <Column $flexGrow={1}>
        <DetailContentHeaderSection />
        <DetailContentBodySection />
      </Column>
    </Row>
  );
};

export default DetailContent;
