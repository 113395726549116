import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import { ApplicationDataType } from '@ydistri/utils';
import { useApplicationData } from '../../../../../../hooks/useApplicationData';
import { useGetPickingInsightsQuery } from '../../../apiCalculationPickingResults';
import React, { useCallback, useMemo } from 'react';
import { StorePickingInsightsResponse } from '@ydistri/api-sdk';
import { getPickInsightsColumns } from '../../../pickingResultsLib';
import LoadingIndicator from '../../../../../../components/global/LoadingIndicator/LoadingIndicator';
import { SimpleTable } from '../../../../Statistics/components/SimpleTable';
import { computeRemSize, tableScroll } from '@ydistri/ds';
import ScrolledTableWrapper from '../../../../../../components/domain/SKUTable/ScrolledTableWrapper';
import { ExpandableConfig } from 'antd/es/table/interface';
import TargetStorePickingInsightsDetailTable from './TargetStorePickingInsightsDetailTable';

const PickingPerformanceTable: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { [ApplicationDataType.STORE_CODE]: hasStoreCodes } = useApplicationData();

  const { data, isFetching } = useGetPickingInsightsQuery(templateOrCalculation.id);

  const tableColumns = useMemo(() => {
    return getPickInsightsColumns(hasStoreCodes);
  }, [hasStoreCodes]);

  const rowKeyProvider = useCallback((record: StorePickingInsightsResponse) => record.store.id, []);

  const expandableConfig: ExpandableConfig<StorePickingInsightsResponse> = useMemo(
    () => ({
      expandedRowRender: record => {
        if (!('store' in record)) return null;
        return <TargetStorePickingInsightsDetailTable sourceStoreId={record.store.id} />;
      },
    }),
    [],
  );

  if (isFetching) {
    return <LoadingIndicator />;
  } else {
    if (data) {
      return (
        <ScrolledTableWrapper offset={40}>
          <SimpleTable<StorePickingInsightsResponse>
            id="store-picking-results"
            scroll={tableScroll}
            dataSource={data}
            columns={tableColumns}
            loading={isFetching}
            showHeader={true}
            height={computeRemSize(480)}
            rowKey={rowKeyProvider}
            showBorder={true}
            expandable={expandableConfig}
          />
        </ScrolledTableWrapper>
      );
    }
  }
};

export default PickingPerformanceTable;
