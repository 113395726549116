import React, { useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import LoadingIndicator from '../../../../../components/global/LoadingIndicator/LoadingIndicator';
import { useCurrency } from '../../../../../hooks/useCurrency';
import { ApplicationDataType } from '@ydistri/utils';
import { useApplicationData } from '../../../../../hooks/useApplicationData';
import { SimpleTable } from '../../../Statistics/components/SimpleTable';
import { useGetTargetStoresPickingResultsQuery } from '../../apiCalculationPickingResults';
import {
  evaluationsRowClassName,
  getBasePickResultsColumns,
  SourceStorePickingResultExtendedResponse,
} from '../../pickingResultsLib';
import EmbeddedTableWrapper from '../../components/EmbeddedTableWrapper';

interface TargetStoreDetailTableProps {
  sourceStoreId: number;
}

const TargetStorePickingResultsDetailTable: React.FC<TargetStoreDetailTableProps> = ({
  sourceStoreId,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const clusterType = useSelector((state: ReduxState) => state.evaluations.clusterType);
  const currencyId = useCurrency() ?? '...';
  const { [ApplicationDataType.STORE_CODE]: hasStoreCodes } = useApplicationData();

  const { data, isFetching: isFetchingStatistics } = useGetTargetStoresPickingResultsQuery({
    calculationId: templateOrCalculation.id,
    clusterType,
    sourceStoreId,
  });

  const columns = useMemo(
    () => getBasePickResultsColumns(currencyId, hasStoreCodes, false),
    [hasStoreCodes, currencyId],
  );

  return (
    <>
      {isFetchingStatistics && <LoadingIndicator />}
      {data && (
        <EmbeddedTableWrapper title="Target stores">
          <SimpleTable<SourceStorePickingResultExtendedResponse>
            id={`target-store-picking-results-${sourceStoreId}`}
            dataSource={data}
            columns={columns}
            loading={isFetchingStatistics}
            showHeader={true}
            rowClassName={evaluationsRowClassName}
          />
        </EmbeddedTableWrapper>
      )}
    </>
  );
};

export default TargetStorePickingResultsDetailTable;
