import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import { ApplicationDataType } from '@ydistri/utils';
import { useApplicationData } from '../../../../../../hooks/useApplicationData';
import {
  useGetPickingInsightsQuery,
  useGetPickingIssuesQuery,
} from '../../../apiCalculationPickingResults';
import {
  getPickingIssuesColumns,
  PickingIssuesData,
  preparePickingIssuesData,
} from '../pickingIssuesLib';
import { CalculationPickingIssuesResponse } from '@ydistri/api-sdk';
import LoadingIndicator from '../../../../../../components/global/LoadingIndicator/LoadingIndicator';
import ScrolledTableWrapper from '../../../../../../components/domain/SKUTable/ScrolledTableWrapper';
import { SimpleTable } from '../../../../Statistics/components/SimpleTable';
import { tableScroll } from '@ydistri/ds';
import { useGetStatsSourceStoresQuery } from '../../../../Statistics/apiCalculationStatistics';
import { useCurrency } from '../../../../../../hooks/useCurrency';
import { ExpandableConfig } from 'antd/es/table/interface';
import SourceStorePickingIssuesTable from './SourceStorePickingIssuesTable';

const PickingIssuesTable: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currency = useCurrency();
  const { [ApplicationDataType.STORE_CODE]: hasStoreCodes } = useApplicationData();

  const { data, isFetching } = useGetPickingIssuesQuery(templateOrCalculation.id);

  const { data: stats, isFetching: isFetchingStats } = useGetStatsSourceStoresQuery({
    calculationId: templateOrCalculation.id,
    tableOrChart: 'table',
  });

  const { data: pickingInsights, isFetching: isFetchingPickingInsights } =
    useGetPickingInsightsQuery(templateOrCalculation.id);

  const combinedData = useMemo(() => {
    const dataReady = stats && data && pickingInsights;
    const isAnyFetching = isFetchingStats || isFetchingPickingInsights || isFetching;

    if (!isAnyFetching && dataReady) {
      return preparePickingIssuesData(stats, data, pickingInsights);
    }
  }, [data, isFetching, isFetchingPickingInsights, isFetchingStats, pickingInsights, stats]);

  const tableColumns = useMemo(() => {
    if (combinedData) {
      return getPickingIssuesColumns(combinedData, currency, hasStoreCodes);
    }
  }, [combinedData, currency, hasStoreCodes]);

  const rowKeyProvider = useCallback(
    (record: CalculationPickingIssuesResponse) => record.store.id,
    [],
  );

  const expandableConfig: ExpandableConfig<PickingIssuesData> = useMemo(
    () => ({
      expandedRowRender: record => {
        if (!('store' in record)) return null;
        return (
          <SourceStorePickingIssuesTable
            calculationId={templateOrCalculation.id}
            sourceStoreId={record.store.id}
          />
        );
      },
    }),
    [templateOrCalculation.id],
  );

  if (isFetching || isFetchingStats) {
    return <LoadingIndicator />;
  } else {
    if (data) {
      return (
        <ScrolledTableWrapper offset={60}>
          <SimpleTable<PickingIssuesData>
            id="store-picking-issues"
            scroll={tableScroll}
            dataSource={combinedData}
            columns={tableColumns}
            loading={isFetching}
            showHeader={true}
            rowKey={rowKeyProvider}
            showBorder={true}
            expandable={expandableConfig}
          />
        </ScrolledTableWrapper>
      );
    }
  }
};

export default PickingIssuesTable;
