import { ColumnsType } from 'antd/es/table';
import {
  CalculationPickingResponse,
  EvaluationRatesResponse,
  PapProgressStatus,
  RoutesEvaluationRatesResponse,
  SourceStorePickingResultResponse,
  StorePickingInsightsResponse,
} from '@ydistri/api-sdk';
import { computeRemSize } from '@ydistri/ds';
import { formatNumber, formatTimeDuration } from '@ydistri/utils';

export enum PickingResultsPart {
  EXECUTION = 'execution',
  PERFORMANCE = 'performance',
  ISSUES = 'issues',
}

export type PickingResultsExtended = Partial<EvaluationRatesResponse> &
  Partial<RoutesEvaluationRatesResponse> & {
    executedQuantityPercentage: number;
    executedValuePercentage: number;
    executedSkuCountPercentage: number;
  };

export type SourceStorePickingResultExtendedResponse = Pick<
  SourceStorePickingResultResponse,
  'store' | 'progressStatus' | 'department'
> & {
  pickingResults: PickingResultsExtended;
};

export type CalculationPickingExtendedResponse = Pick<
  CalculationPickingResponse,
  'calculationCreatedAt' | 'pickingStatus' | 'finishExecutionUntil'
> & {
  pickingResults: PickingResultsExtended;
};

const statusMap: { [key in PapProgressStatus]: string } = {
  [PapProgressStatus.NotStarted]: 'Not started',
  [PapProgressStatus.PickingStarted]: 'Started',
  [PapProgressStatus.PickingDone]: 'Picking completed',
  [PapProgressStatus.Finalized]: 'Finalized',
};

export const translatePapProgressStatus = (status: PapProgressStatus): string => {
  return statusMap[status] || 'Unknown status';
};

export const evaluationsRowClassName = (
  rowObject: SourceStorePickingResultExtendedResponse,
): string => {
  if (rowObject.progressStatus === PapProgressStatus.Finalized) return 'green';
  if (rowObject.progressStatus === PapProgressStatus.NotStarted) return 'red';
  return 'orange';
};

const PICKING_RESULTS_PROPERTY = 'pickingResults';

export const getBasePickResultsColumns = (
  currency: string,
  hasStoreCodes: boolean,
  includeRoutes: boolean = true,
  colWidth: number = 150,
): ColumnsType<SourceStorePickingResultExtendedResponse> => {
  const columns: ColumnsType<SourceStorePickingResultExtendedResponse> = [];

  columns.push({
    title: `Store ID`,
    dataIndex: ['store', 'customerStoreId'],
    key: 'storeCustomerId',
    width: computeRemSize(100),
  });

  if (hasStoreCodes) {
    columns.push({
      title: `Store Code`,
      dataIndex: ['store', 'code'],
      key: 'storeCustomerCode',
      width: computeRemSize(100),
    });
  }

  columns.push(
    {
      title: `Store Name`,
      dataIndex: ['store', 'name'],
      key: 'storeName',
    },
    {
      title: 'Picking Status',
      dataIndex: ['progressStatus'],
      key: 'progressStatus',
      width: computeRemSize(100),
      render: (value: PapProgressStatus) => translatePapProgressStatus(value),
    },
    {
      title: `Executed Value [${currency}]`,
      dataIndex: [PICKING_RESULTS_PROPERTY, 'executedValue'],
      key: 'executedValue',
      width: computeRemSize(160),
      sorter: (a, b) =>
        (a.pickingResults.executedValue ?? 0) - (b.pickingResults.executedValue ?? 0),
      render: (value: number) => formatNumber(value, 0),
      align: 'right',
    },
    {
      title: `Total Value [${currency}]`,
      dataIndex: [PICKING_RESULTS_PROPERTY, 'totalValue'],
      key: 'totalValue',
      width: computeRemSize(colWidth),
      sorter: (a, b) => (a.pickingResults.totalValue ?? 0) - (b.pickingResults.totalValue ?? 0),
      render: (value: number) => formatNumber(value, 0),
      align: 'right',
    },
    {
      title: 'Executed Value [%]',
      dataIndex: [PICKING_RESULTS_PROPERTY, 'executedValuePercentage'],
      key: 'executedValuePercentage',
      width: computeRemSize(colWidth),
      sorter: (a, b) =>
        a.pickingResults.executedValuePercentage - b.pickingResults.executedValuePercentage,
      defaultSortOrder: 'ascend',
      render: (value: number) => formatNumber(value, 0),
      align: 'right',
    },
    {
      title: `Executed SKUs`,
      dataIndex: [PICKING_RESULTS_PROPERTY, 'executedSkuCount'],
      key: 'executedSkuCount',
      width: computeRemSize(colWidth),
      sorter: (a, b) =>
        (a.pickingResults.executedSkuCount ?? 0) - (b.pickingResults.executedSkuCount ?? 0),
      align: 'right',
    },
    {
      title: `Total SKUs`,
      dataIndex: [PICKING_RESULTS_PROPERTY, 'totalSkuCount'],
      key: 'totalSkuCount',
      width: computeRemSize(colWidth),
      sorter: (a, b) =>
        (a.pickingResults.totalSkuCount ?? 0) - (b.pickingResults.totalSkuCount ?? 0),
      align: 'right',
    },
    {
      title: 'Executed SKUs [%]',
      dataIndex: [PICKING_RESULTS_PROPERTY, 'executedSkuCountPercentage'],
      key: 'executedSkuCountPercentage',
      width: computeRemSize(110),
      sorter: (a, b) =>
        (a.pickingResults.executedSkuCountPercentage ?? 0) -
        (b.pickingResults.executedSkuCountPercentage ?? 0),
      align: 'right',
    },
  );

  if (includeRoutes) {
    columns.push(
      {
        title: `Executed Routes`,
        dataIndex: [PICKING_RESULTS_PROPERTY, 'executedRouteCount'],
        key: 'executedRouteCount',
        width: computeRemSize(colWidth),
        sorter: (a, b) =>
          'executedRouteCount' in a && 'executedRouteCount' in b
            ? (a.pickingResults.executedRouteCount ?? 0) -
              (b.pickingResults.executedRouteCount ?? 0)
            : 0,
        render: (value: number) => formatNumber(value, 0),
        align: 'right',
      },
      {
        title: `Total Routes`,
        dataIndex: [PICKING_RESULTS_PROPERTY, 'totalRouteCount'],
        key: 'totalRouteCount',
        width: computeRemSize(colWidth),
        sorter: (a, b) =>
          'totalRouteCount' in a && 'totalRouteCount' in b
            ? (a.pickingResults.totalRouteCount ?? 0) - (b.pickingResults.totalRouteCount ?? 0)
            : 0,
        render: (value: number) => formatNumber(value, 0),
        align: 'right',
      },
    );
  }

  return columns;
};

export const getPickInsightsColumns = (
  hasStoreCodes: boolean,
): ColumnsType<StorePickingInsightsResponse> => {
  const columns: ColumnsType<StorePickingInsightsResponse> = [
    {
      title: `Store ID`,
      dataIndex: ['store', 'customerStoreId'],
      key: 'storeCustomerId',
      width: computeRemSize(100),
      align: 'center',
    },
  ];

  if (hasStoreCodes) {
    columns.push({
      title: `Store Code`,
      dataIndex: ['store', 'code'],
      key: 'storeCustomerCode',
      width: computeRemSize(100),
    });
  }

  columns.push(
    {
      title: `Store Name`,
      dataIndex: ['store', 'name'],
      key: 'storeName',
    },
    {
      title: 'Executed Quantity',
      dataIndex: 'executedQuantity',
      key: 'executedQuantity',
      width: computeRemSize(160),
      align: 'right',
      sorter: (a, b) => (a.executedQuantity ?? 0) - (b.executedQuantity ?? 0),
    },
    {
      title: 'Executed Value',
      dataIndex: 'executedValue',
      key: 'executedValue',
      width: computeRemSize(160),
      align: 'right',
      render: (value: number) => formatNumber(value),
      sorter: (a, b) => (a.executedValue ?? 0) - (b.executedValue ?? 0),
    },
    {
      title: 'SKU Count',
      dataIndex: 'skuCount',
      key: 'skuCount',
      width: computeRemSize(100),
      align: 'right',
      sorter: (a, b) => (a.skuCount ?? 0) - (b.skuCount ?? 0),
    },
    {
      title: 'Picking Time (trimmed)',
      dataIndex: 'trimmedPickingTime',
      key: 'trimmedPickingTime',
      width: computeRemSize(160),
      align: 'right',
      render: (value: number) => formatTimeDuration(value, ['hours', 'minutes']),
      sorter: (a, b) => (a.trimmedPickingTime ?? 0) - (b.trimmedPickingTime ?? 0),
    },
    {
      title: 'Mean Picking Time (trimmed)',
      dataIndex: 'trimmedMeanPickingTimePerSku',
      key: 'trimmedMeanPickingTimePerSku',
      width: computeRemSize(230),
      align: 'right',
      render: (value: number) => formatTimeDuration(value),
      sorter: (a, b) =>
        (a.trimmedMeanPickingTimePerSku ?? 0) - (b.trimmedMeanPickingTimePerSku ?? 0),
    },
    {
      title: 'Mean Picking Time',
      dataIndex: 'meanPickingTimePerSku',
      key: 'meanPickingTimePerSku',
      width: computeRemSize(200),
      align: 'right',
      render: (value: number) => formatTimeDuration(value),
      sorter: (a, b) => (a.meanPickingTimePerSku ?? 0) - (b.meanPickingTimePerSku ?? 0),
    },
    {
      title: 'Median Picking Time',
      dataIndex: 'medianPickingTime',
      key: 'medianPickingTime',
      width: computeRemSize(200),
      align: 'right',
      render: (value: number) => formatTimeDuration(value),
      sorter: (a, b) => (a.medianPickingTime ?? 0) - (b.medianPickingTime ?? 0),
    },
  );

  return columns;
};
