import { ColumnsType } from 'antd/es/table';
import {
  CalculationPickingIssuesResponse,
  CalculationStatisticStoreSourceResponse,
  PapRedistributionIssue,
  PickingIssue,
  SourceStorePickingIssuesResponse,
  StorePickingInsightsResponse,
} from '@ydistri/api-sdk';
import { computeRemSize } from '@ydistri/ds';
import OutlierValue from '../../components/OutlierValue';
import { formatNumber } from '@ydistri/utils';

export type PickingIssuesData = CalculationStatisticStoreSourceResponse &
  CalculationPickingIssuesResponse &
  Pick<StorePickingInsightsResponse, 'executedQuantity' | 'executedValue' | 'skuCount'>;

const issuesMap: { [key in PapRedistributionIssue]: string } = {
  [PapRedistributionIssue.DamagedOrExpired]: 'Damaged / Expired',
  [PapRedistributionIssue.NotSending]: 'Not Sending More',
  [PapRedistributionIssue.NotFound]: 'Not Found',
  [PapRedistributionIssue.Other]: 'Other',
  [PapRedistributionIssue.DifferentSupply]: 'Different Supply',
  [PapRedistributionIssue.SkipTemporarily]: 'Skipped Temporarily',
  [PapRedistributionIssue.SkipPermanently]: 'Skipped Permanently',
};

export const translateRedistributionIssue = (issue: PapRedistributionIssue): string => {
  return issuesMap[issue] || 'Unknown issue';
};

export const preparePickingIssuesData = (
  stats: CalculationStatisticStoreSourceResponse[],
  issues: CalculationPickingIssuesResponse[],
  pickingInsights: StorePickingInsightsResponse[],
): PickingIssuesData[] => {
  return issues.map(issue => {
    const matchedStat = stats.find(stat => stat.sourceStore.id === issue.store.id);
    const matchedInsight = pickingInsights.find(insight => insight.store.id === issue.store.id);

    const result: PickingIssuesData = {
      store: issue.store,
      sourceStore: matchedStat ? matchedStat.sourceStore : issue.store,
      issues: issue.issues,
      value: matchedStat ? matchedStat.value : 0,
      quantity: matchedStat ? matchedStat.quantity : 0,
      productCount: matchedStat ? matchedStat.productCount : 0,
      targetStoreCount: matchedStat ? matchedStat.targetStoreCount : 0,
      count: issue.count,
      executedQuantity: matchedInsight ? matchedInsight.executedQuantity : 0,
      executedValue: matchedInsight ? matchedInsight.executedValue : 0,
      skuCount: matchedInsight ? matchedInsight.skuCount : 0,
    };

    return result;
  });
};

const getIssueCount = (
  issues: PickingIssue[],
  issueType: PapRedistributionIssue,
): number | undefined => {
  const issue = issues.find(issue => issue.papRedistributionIssueId === issueType);
  if (issue) {
    return issue.count;
  }
};

const issueCellRenderer = (
  record: CalculationPickingIssuesResponse,
  issueType: PapRedistributionIssue,
) => {
  const value = getIssueCount(record.issues, issueType);
  if (value) {
    const allValues = record.issues.map(issue => issue.count);
    const outlier = isOutlier(allValues, value);
    return outlier ? <OutlierValue value={value} /> : value;
  }
};

export const getPickingIssuesColumns = (
  data: PickingIssuesData[],
  currency: string,
  hasStoreCodes: boolean,
): ColumnsType<PickingIssuesData> => {
  const columns: ColumnsType<PickingIssuesData> = [];

  columns.push({
    title: `Store ID`,
    dataIndex: ['store', 'customerStoreId'],
    key: 'storeCustomerId',
    width: computeRemSize(100),
  });

  if (hasStoreCodes) {
    columns.push({
      title: `Store Code`,
      dataIndex: ['store', 'code'],
      key: 'storeCustomerCode',
      width: computeRemSize(100),
    });
  }

  columns.push(
    {
      title: `Store Name`,
      dataIndex: ['store', 'name'],
      key: 'storeName',
    },
    {
      title: 'Quantity',
      key: 'quantity',
      align: 'right',
      width: computeRemSize(120),
      render: (record: PickingIssuesData) => {
        const diff = record.quantity - record.executedQuantity;
        const value = `${record.executedQuantity} / ${record.quantity}   ( ${formatNumber(-diff)} )`;

        const observations = data.map(record => record.quantity - record.executedQuantity);
        const outlier = isOutlier(observations, diff);
        return outlier ? <OutlierValue value={value} /> : value;
      },
      sorter: (a: PickingIssuesData, b: PickingIssuesData) => {
        //when sorted in ascending order, the smallest difference should be first
        //so diff is a positive number
        const diffA = a.quantity - a.executedQuantity;
        const diffB = b.quantity - b.executedQuantity;

        return diffA - diffB;
      },
    },
    {
      title: 'Value',
      key: 'value',
      align: 'right',
      width: computeRemSize(180),
      render: (record: PickingIssuesData) => {
        const diff = record.value - record.executedValue;
        const value = `${formatNumber(record.executedValue)} / ${formatNumber(record.value)}   ( ${formatNumber(-diff)} )`;
        const observations = data.map(record => record.value - record.executedValue);
        const outlier = isOutlier(observations, diff);
        return outlier ? <OutlierValue value={value} /> : value;
      },
      sorter: (a: PickingIssuesData, b: PickingIssuesData) => {
        //when sorted in ascending order, the smallest difference should be first
        //so diff is a positive number
        const diffA = a.value - a.executedValue;
        const diffB = b.value - b.executedValue;

        return diffA - diffB;
      },
    },
    {
      title: 'SKU Count',
      dataIndex: 'skuCount',
      key: 'skuCount',
      align: 'right',
      width: computeRemSize(120),
    },
    {
      title: 'Total Issues',
      dataIndex: 'count',
      key: 'totalIssuesCount',
      align: 'center',
      width: computeRemSize(100),
      render: (value: number) => {
        const observations = data.map(record => record.count);
        const outlier = isOutlier(observations, value);
        return outlier ? <OutlierValue value={value} /> : value;
      },
      sorter: (a: PickingIssuesData, b: PickingIssuesData) => a.count - b.count,
    },
    {
      title: 'Different Supply',
      key: 'differentSupply',
      align: 'center',
      width: computeRemSize(120),
      render: (record: CalculationPickingIssuesResponse) =>
        issueCellRenderer(record, PapRedistributionIssue.DifferentSupply),
    },
    {
      title: 'Damaged / Expired',
      key: 'damagedOrExpired',
      align: 'center',
      width: computeRemSize(120),
      render: (record: CalculationPickingIssuesResponse) =>
        issueCellRenderer(record, PapRedistributionIssue.DamagedOrExpired),
      sorter: (a: PickingIssuesData, b: PickingIssuesData) =>
        (getIssueCount(a.issues, PapRedistributionIssue.DamagedOrExpired) ?? 0) -
        (getIssueCount(b.issues, PapRedistributionIssue.DamagedOrExpired) ?? 0),
    },
    {
      title: 'Not Found',
      key: 'notFound',
      align: 'center',
      width: computeRemSize(120),
      render: (record: CalculationPickingIssuesResponse) =>
        issueCellRenderer(record, PapRedistributionIssue.NotFound),
    },
    {
      title: 'Not Sending More',
      key: 'NotSendingMore',
      align: 'center',
      width: computeRemSize(120),
      render: (record: CalculationPickingIssuesResponse) =>
        issueCellRenderer(record, PapRedistributionIssue.NotSending),
    },
    {
      title: 'Skipped Permanently',
      key: 'skipPermanently',
      align: 'center',
      width: computeRemSize(120),
      render: (record: CalculationPickingIssuesResponse) =>
        issueCellRenderer(record, PapRedistributionIssue.SkipPermanently),
    },
    {
      title: 'Skipped Temporarily',
      key: 'skipTemporarily',
      align: 'center',
      width: computeRemSize(120),
      render: (record: CalculationPickingIssuesResponse) =>
        issueCellRenderer(record, PapRedistributionIssue.SkipTemporarily),
    },
    {
      title: 'Other',
      key: 'other',
      align: 'center',
      width: computeRemSize(100),
      render: (record: CalculationPickingIssuesResponse) =>
        issueCellRenderer(record, PapRedistributionIssue.Other),
    },
  );

  return columns;
};

export const getSourceStorePickingIssuesColumns = (
  hasStoreCodes: boolean,
): ColumnsType<SourceStorePickingIssuesResponse> => {
  const columns: ColumnsType<SourceStorePickingIssuesResponse> = [
    {
      title: 'Product Id',
      width: computeRemSize(100),
      render: (record: SourceStorePickingIssuesResponse) =>
        record.product.customerId ?? record.product.id,
      sorter: (a: SourceStorePickingIssuesResponse, b: SourceStorePickingIssuesResponse) => {
        const aId = a.product.customerId ?? a.product.id.toString();
        const bId = b.product.customerId ?? b.product.id.toString();
        return aId.localeCompare(bId);
      },
    },
    {
      title: 'Product',
      dataIndex: ['product', 'name'],
      sorter: (a: SourceStorePickingIssuesResponse, b: SourceStorePickingIssuesResponse) => {
        return a.product.name.localeCompare(b.product.name);
      },
    },
    {
      title: 'Brand',
      dataIndex: ['product', 'brand', 'name'],
      sorter: (a: SourceStorePickingIssuesResponse, b: SourceStorePickingIssuesResponse) => {
        return a.product.brand?.name.localeCompare(b.product.brand?.name ?? '') ?? 0;
      },
    },
    {
      title: 'Target Store Id',
      dataIndex: ['targetStore', 'customerStoreId'],
      width: computeRemSize(120),
      sorter: (a: SourceStorePickingIssuesResponse, b: SourceStorePickingIssuesResponse) => {
        return (
          a.targetStore.customerStoreId?.localeCompare(b.targetStore.customerStoreId ?? '') ?? 0
        );
      },
    },
  ];

  if (hasStoreCodes) {
    columns.push({
      title: `Target Store Code`,
      dataIndex: ['targetStore', 'code'],
      key: 'storeCustomerCode',
      width: computeRemSize(100),
    });
  }

  columns.push(
    {
      title: `Target Store Name`,
      dataIndex: ['targetStore', 'name'],
      key: 'storeName',
    },
    {
      title: 'Issue',
      key: 'issue',
      width: computeRemSize(140),
      sorter: (a: SourceStorePickingIssuesResponse, b: SourceStorePickingIssuesResponse) => {
        const issueA = translateRedistributionIssue(a.papRedistributionIssueId);
        const issueB = translateRedistributionIssue(b.papRedistributionIssueId);
        return issueA.localeCompare(issueB);
      },
      render: (record: SourceStorePickingIssuesResponse) =>
        translateRedistributionIssue(record.papRedistributionIssueId),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      align: 'right',
      width: computeRemSize(120),
      render: (record: SourceStorePickingIssuesResponse) => {
        const diff = record.quantityExecuted - record.quantityRequested;
        return `${record.quantityExecuted} / ${record.quantityRequested}   ( ${formatNumber(diff)} )`;
      },
    },
  );

  return columns;
};

const isOutlier = (observations: number[], value: number): boolean => {
  // Helper function to calculate median
  const median = (nums: number[]): number => {
    const sorted = [...nums].sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
  };

  // Helper function to calculate MAD (Median Absolute Deviation)
  const mad = (nums: number[], med: number): number => {
    const absDeviations = nums.map(num => Math.abs(num - med));
    return median(absDeviations);
  };

  const med = median(observations);
  const madValue = mad(observations, med);
  const threshold = 3 * madValue;

  const deviation = value - med; //not computing absolute value, because we are interested in positive deviations only
  return deviation > threshold;
};
