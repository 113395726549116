import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { ConfigurationSelect } from '@ydistri/ds';
import { ConfigurationFieldStatus } from '@ydistri/utils';
import { createDebugLog } from '../../../lib/utils/logging';
import { styled } from 'styled-components';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import MultiselectTag from './MultiselectTag';
import { ConfigurationRuleScopeEntityType } from '@ydistri/api-sdk';
import { getScopeErrorText } from './scopeLib';
import MultiselectValidationModal from '../../Modals/MultiselectValidationModal';

const debugLog = createDebugLog('ConfigurationMultiselect');

const WrapperDiv = styled.div`
  width: 100%;
`;

export interface MultiselectCustomerPartialEntity {
  id: number;
  title: string;
  customerId: string;
  code?: string | null;
  disabled?: boolean;
}

export type MultiselectKeysToParse = keyof Omit<
  MultiselectCustomerPartialEntity,
  'id' | 'title' | 'disabled'
>;
export type MultiselectParseTypeCounter = Record<MultiselectKeysToParse | 'total', number>;
export type MultiselectParseTypeAvailability = Record<MultiselectKeysToParse, boolean>;

export interface MultiselectParseResponse {
  ids: number[];
  parsedBy: MultiselectKeysToParse;
  notRecognized: string[];
  counter: MultiselectParseTypeCounter;
  availability: MultiselectParseTypeAvailability;
}

export const parseTabularDataFromHtml = <T extends MultiselectCustomerPartialEntity>(
  data: T[],
  content: string,
): MultiselectParseResponse => {
  const response: MultiselectParseResponse = {
    ids: [],
    parsedBy: 'customerId',
    notRecognized: [],
    counter: {
      customerId: 0,
      code: 0,
      total: 0,
    },
    availability: {
      customerId: true,
      code: false,
    },
  };

  const parser = new DOMParser();

  const htmlDoc = parser.parseFromString(content, 'text/html');
  const strippedContent = htmlDoc.body.textContent || content;
  const rows = htmlDoc.querySelectorAll('table tr');

  const foundValues: string[] = [];

  debugLog('rows', rows, 'length', rows.length);

  if (rows.length > 0) {
    rows.forEach(row => {
      const cells = row.querySelectorAll('td');

      cells.forEach(cell => {
        let content = cell.innerText;
        if (!content) {
          //fallback when innerText is not supported
          content = cell.firstChild?.textContent ?? '';
        }
        if (content.length > 0) {
          foundValues.push(content.trim());
        }
      });
    });
  } else {
    const splitData = strippedContent.split(/\s+|\n+|\r+|\t+|,|;/g);
    splitData.forEach(value => {
      const v = value.trim();
      if (v.length > 0) foundValues.push(v);
    });
  }

  response.counter.total = foundValues.length;

  const hasCode = !!data[0].code;
  response.availability.code = hasCode;

  let dataByCode: T[] = [];
  const dataByCustomerId: T[] = data.filter(r => foundValues.includes(r.customerId));
  response.counter.customerId = dataByCustomerId.length;

  if (dataByCustomerId.length < foundValues.length) {
    dataByCode = hasCode ? data.filter(r => r.code && foundValues.includes(r.code)) : [];
    if (dataByCode.length === foundValues.length || dataByCustomerId.length < dataByCode.length) {
      response.parsedBy = 'code';
    }
    response.counter.code = dataByCode.length;
  }

  const filteredData = response.parsedBy === 'code' ? dataByCode : dataByCustomerId;
  response.ids = filteredData.map(r => r.id);
  response.notRecognized = foundValues.filter(
    v => !filteredData.map(r => r[response.parsedBy]).includes(v),
  );

  return response;
};

interface ConfigurationMultiselectProps<T extends MultiselectCustomerPartialEntity> {
  value: number[];
  onValueChange: (value: number[]) => void;
  data: T[];
  disabled?: boolean;
  loading?: boolean;
  entityType: ConfigurationRuleScopeEntityType;
}

const ConfigurationMultiselect = <T extends MultiselectCustomerPartialEntity>({
  value,
  onValueChange,
  data,
  disabled,
  loading,
  entityType,
}: ConfigurationMultiselectProps<T>): React.ReactElement => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [pastedData, setPastedData] = useState<string | undefined>();
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [parseResponse, setParseResponse] = useState<MultiselectParseResponse>();
  // const [openedValidationModal, setOpenedValidationModal] = useState<boolean>(false);

  useEffect(() => {
    if (pastedData) {
      const parsedEvent: MultiselectParseResponse = parseTabularDataFromHtml(data, pastedData);
      setParseResponse(parsedEvent);
      // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
      setPastedData(undefined);
      // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
      setSearchValue(undefined);
    }
  }, [data, pastedData, onValueChange]);

  const tagRender = useCallback(
    (props: CustomTagProps) => {
      // eslint-disable-next-line react/jsx-no-useless-fragment,@typescript-eslint/no-unnecessary-condition
      if (!props) return <></>;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { label, value, closable, onClose } = props;
      console.log('TAG RENDER!', props);

      return (
        <MultiselectTag
          entityType={entityType}
          closable={closable}
          onClose={onClose}
          error={getScopeErrorText(0, label === value, entityType)}
          label={label}
          //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value={value}
          disabled={disabled}
          //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          entityId={value as unknown as number}
        />
      );
    },
    [disabled, entityType],
  );

  const clearParsedData = useCallback(() => {
    setParseResponse(undefined);
  }, []);

  const setDataHandler = useCallback(
    (value: number[]) => {
      clearParsedData();
      onValueChange(value);
    },
    [clearParsedData, onValueChange],
  );

  const openedValidationModal = useMemo(() => {
    if (parseResponse) {
      if (parseResponse.notRecognized.length > 0) {
        console.log('OPENING>...');
        return true;
      } else {
        console.log('CLOSING>...');
        onValueChange(parseResponse.ids);
        clearParsedData();
        return false;
      }
    }
  }, [clearParsedData, parseResponse, onValueChange]);

  const options: DefaultOptionType[] = data.map(dataItem => ({
    value: dataItem.id,
    label: `#${dataItem.customerId ?? dataItem.id} - ${dataItem.title}`,
  }));

  const onChangeHandler = useCallback(
    (input: number[]) => {
      onValueChange(input);
    },
    [onValueChange],
  );

  useEffect(() => {
    if (wrapperRef.current) {
      const inputElement = wrapperRef.current.querySelector('input');

      if (inputElement) {
        console.log(`inputElement:`, inputElement);
        const handlePaste = (event: ClipboardEvent) => {
          const htmlData = event.clipboardData?.getData('text/html');
          if (htmlData) {
            console.log('htmlData:', htmlData);
            // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
            setPastedData(htmlData);
          } else {
            console.log('nonHtmlData:', event.clipboardData?.getData('text/plain'));
            // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
            setPastedData(event.clipboardData?.getData('text/plain'));
          }
        };

        inputElement.addEventListener('paste', handlePaste);

        return () => {
          inputElement.removeEventListener('paste', handlePaste);
        };
      }
    }
  }, [wrapperRef]);

  return (
    <WrapperDiv ref={wrapperRef}>
      <ConfigurationSelect<number[]>
        disabled={disabled}
        mode="multiple"
        tagRender={tagRender}
        placeholder="Select or paste data"
        value={value}
        onChange={onChangeHandler}
        options={options}
        width="100%"
        status={
          value.length === 0 ? ConfigurationFieldStatus.ERROR : ConfigurationFieldStatus.DEFAULT
        }
        disableOnLoading={false}
        size="large"
        autoClearSearchValue={true}
        searchValue={searchValue}
        loading={loading}
      />
      {openedValidationModal && (
        <MultiselectValidationModal
          parsedData={parseResponse}
          onSubmit={setDataHandler}
          onClose={clearParsedData}
        />
      )}
    </WrapperDiv>
  );
};

export default ConfigurationMultiselect;
