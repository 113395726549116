import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import { useGetTargetStoresPickingInsightsQuery } from '../../../apiCalculationPickingResults';
import EmbeddedTableWrapper from '../../../components/EmbeddedTableWrapper';
import LoadingIndicator from '../../../../../../components/global/LoadingIndicator/LoadingIndicator';
import React, { useMemo } from 'react';
import { getPickInsightsColumns } from '../../../pickingResultsLib';
import { ApplicationDataType } from '@ydistri/utils';
import { useApplicationData } from '../../../../../../hooks/useApplicationData';
import { SimpleTable } from '../../../../Statistics/components/SimpleTable';
import { StorePickingInsightsResponse } from '@ydistri/api-sdk';

interface TargetStoreDetailTableProps {
  sourceStoreId: number;
}

const TargetStorePickingResultsDetailTable: React.FC<TargetStoreDetailTableProps> = ({
  sourceStoreId,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { [ApplicationDataType.STORE_CODE]: hasStoreCodes } = useApplicationData();

  const { data, isFetching } = useGetTargetStoresPickingInsightsQuery({
    calculationId: templateOrCalculation.id,
    sourceStoreId,
  });

  const tableColumns = useMemo(() => {
    return getPickInsightsColumns(hasStoreCodes);
  }, [hasStoreCodes]);

  if (isFetching) {
    return <LoadingIndicator />;
  } else {
    if (data) {
      return (
        <EmbeddedTableWrapper title="Target stores">
          <SimpleTable<StorePickingInsightsResponse>
            id={`target-store-picking-insights-${sourceStoreId}`}
            dataSource={data}
            columns={tableColumns}
            loading={isFetching}
            showHeader={true}
            showBorder={true}
          />
        </EmbeddedTableWrapper>
      );
    }
  }
};

export default TargetStorePickingResultsDetailTable;
