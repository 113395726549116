import React, { useCallback, useMemo, useState } from 'react';
import {
  Column,
  GenericConfirmationModal,
  RowCentered,
  SectionHeaderRowSpaceBetween,
  SectionHeaderRowSpaceBetweenNoBorder,
  Title,
} from '@ydistri/ds';
import { Dropdown, DropdownProps, Popover, Space } from 'antd';
import { icons, IconType } from '../../components/icons/icons';
import { COLORS } from '../../styles/style';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from './apiCalculationDetail';
import CalculationPotentialOverview from '../../components/global/HeaderPotentialOverview/CalculationPotentialOverview';
import PrivateCalculationsIcon from '../Calculations/actions/PrivateCalculationsIcon';
import LoadIntoTemplateAction from '../Calculations/actions/LoadIntoTemplateAction';
import LoadIntoTemplateModal from '../Calculations/modals/LoadIntoTemplateModal';
import EditCalculationModal from './EditCalculationModal';
import {
  PatchCalculationPayload,
  useDeleteCalculationMutation,
  usePatchCalculationMutation,
} from '../Calculations/apiCalculations';
import { CalculationType } from '@ydistri/api-sdk';
import { addToast } from '../../../../../../libs/utils/src/lib/toastSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MenuSubpages from '../../components/menu/MenuSubpages';
import { RoutingPage } from '../../routes/routerSlice';
import { CalculationDetailSubpages } from '../Calculations/calculationsLib';
import useSubpage from '../../hooks/useSubpage';
import MoreIconButton from '../../components/buttons/MoreIconButton';
import { SubmenuOption } from '../Configuration/ConfigurationHeader';
import ProductionCalculationIcon from '../Calculations/actions/ProductionCalculationIcon';
import { useUser } from '../../hooks/useUser';
import { styled } from 'styled-components';
import CalculationTitleItem from './components/CalculationTitleItem';
import { useApplicationData } from '../../hooks/useApplicationData';
import { AvailableModals, openModal } from '../Modals/modalSlice';
import ConfigurationExceptions from '../Configuration/ConfigurationCategories/ConfigurationExceptions';
import { useGetTargetListsForConfigurationQuery } from '../Configuration/apiTargetListConfiguration';
import PapSyncButton from './PapSyncButton';

enum CalculationAction {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

const CalculationTitle = styled(Title)`
  max-width: 60rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CalculationHeader: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subpage = useSubpage();
  const user = useUser();
  const { projectShortName } = useParams();
  const { departments: hasDepartments } = useApplicationData();

  const { data: calculationData } = useGetCalculationQuery(templateOrCalculation.id);
  const { data: targetListConfig } = useGetTargetListsForConfigurationQuery(templateOrCalculation);
  const [openedActionModal, setOpenedActionModal] = useState<CalculationAction>();
  const [showLoadIntoTemplateModal, setShowLoadIntoTemplateModal] = React.useState(false);
  const [editCalculation, { isLoading }] = usePatchCalculationMutation();
  const [deleteCalculation] = useDeleteCalculationMutation();

  const isPrivate = useMemo(
    () => calculationData?.type === CalculationType.Private,
    [calculationData?.type],
  );

  const isProduction = useMemo(
    () => calculationData?.type === CalculationType.Production,
    [calculationData?.type],
  );

  const hasTargetList = useMemo(() => (targetListConfig ?? []).length > 0, [targetListConfig]);

  const showLoadIntoTemplateModalHandler = useCallback(
    () => setShowLoadIntoTemplateModal(true),
    [],
  );
  const closeLoadIntoTemplateModal = useCallback(() => setShowLoadIntoTemplateModal(false), []);
  const onConfirmed = useCallback(() => {}, []);

  const closeOpenedActionModal = useCallback(() => {
    setOpenedActionModal(undefined);
  }, []);

  const deleteCalculationHandler = useCallback(() => {
    if (templateOrCalculation.type === 'Calculation' && projectShortName) {
      navigate(`/${projectShortName}/calculations`);
      deleteCalculation(templateOrCalculation.id)
        .unwrap()
        .then(() => {
          dispatch(addToast({ message: 'Calculation deleted' }));
        })
        .catch(() => {
          dispatch(addToast({ message: 'Calculation was not deleted', isError: true }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectShortName,
    templateOrCalculation.type,
    templateOrCalculation.id,
    deleteCalculation,
    dispatch,
  ]);

  const calculationOptionDropdownItems: DropdownProps['menu'] = useMemo(
    () => ({
      items: [
        {
          label: 'Edit calculation',
          key: '1',
          icon: icons[IconType.EDIT_OUTLINED],
          onClick: () => {
            setOpenedActionModal(CalculationAction.EDIT);
          },
          style: {
            backgroundColor: COLORS.PRIMARY_LIGHT,
          },
        },
        {
          type: 'divider',
        },
        {
          label: 'Delete',
          key: '2',
          icon: icons[IconType.DELETE_OUTLINED],
          onClick: () => {
            setOpenedActionModal(CalculationAction.DELETE);
          },
          danger: true,
        },
      ],
    }),
    [],
  );

  const dropdownTrigger: DropdownProps['trigger'] = useMemo(() => ['click'], []);
  const dropdownAlign: DropdownProps['align'] = useMemo(() => ({ offset: [0, 0] }), []);

  const togglePrivate = useCallback(() => {
    const payload: PatchCalculationPayload = {
      calculationId: templateOrCalculation.id,
      data: {
        calculationType: isPrivate ? CalculationType.Simulation : CalculationType.Private,
      },
    };
    editCalculation(payload);
  }, [editCalculation, isPrivate, templateOrCalculation.id]);

  const toggleProduction = useCallback(() => {
    dispatch(
      openModal({
        type: isProduction ? AvailableModals.UNSET_PRODUCTION : AvailableModals.SET_PRODUCTION,
        data: {
          calculationId: templateOrCalculation.id,
        },
      }),
    );
  }, [dispatch, isProduction, templateOrCalculation.id]);

  const submenuOptions: SubmenuOption[] = useMemo(() => {
    const options: SubmenuOption[] = [
      {
        value: CalculationDetailSubpages.CATEGORIES,
        label: 'Categories',
      },
      {
        value: CalculationDetailSubpages.REGIONS,
        label: 'Regions',
      },
      {
        value: CalculationDetailSubpages.STORES_AND_DEPARTMENTS,
        label: hasDepartments ? 'Stores and departments' : 'Stores',
      },
      {
        value: CalculationDetailSubpages.SKUCLASSES,
        label: 'SKU classes',
      },
      {
        value: CalculationDetailSubpages.PRODUCTLISTS,
        label: 'Product lists',
      },
      {
        value: CalculationDetailSubpages.BRANDS,
        label: 'Brands',
        appendDivider: !user?.isSuperUser,
      },
    ];

    if (user?.isSuperUser) {
      options.push({
        value: CalculationDetailSubpages.ADVANCED,
        label: 'Advanced',
        appendDivider: true,
      });
    }

    options.push(
      ...[
        {
          value: CalculationDetailSubpages.DRILLDOWN,
          label: 'Drill down',
        },
        {
          value: CalculationDetailSubpages.REDISTRIBUTION,
          label: 'Redistribution',
        },
        {
          value: CalculationDetailSubpages.STATISTICS,
          label: 'Statistics',
          appendDivider: isProduction && !hasTargetList,
        },
        {
          value: CalculationDetailSubpages.TARGET_LIST_STATISTICS,
          label: 'Target list',
          isVisible: hasTargetList,
          appendDivider: isProduction,
        },
        {
          value: CalculationDetailSubpages.EVALUATION,
          label: 'Evaluation',
          isVisible: isProduction && !calculationData?.isPap,
        },
        {
          value: CalculationDetailSubpages.PICKING_RESULTS,
          label: 'Picking',
          isVisible: isProduction && calculationData?.isPap,
        },
      ],
    );

    return options;
  }, [hasDepartments, hasTargetList, isProduction, user?.isSuperUser, calculationData?.isPap]);

  const calculationTitle = useMemo(
    () => `#${calculationData?.id} ${calculationData?.title}`,
    [calculationData?.id, calculationData?.title],
  );

  return (
    <>
      <SectionHeaderRowSpaceBetween>
        <RowCentered $gap="1rem">
          <Column $maxWidth="60rem">
            <RowCentered>
              <Popover content={calculationTitle}>
                <CalculationTitle $size="large">{calculationTitle}</CalculationTitle>
              </Popover>
            </RowCentered>
            <RowCentered $gap="1rem" $flexWrap="nowrap" $maxWidth="60rem">
              <CalculationTitleItem
                label="Template"
                labelWidth="5rem"
                content={
                  /* WARNING !!! Source template actually CAN be null, in case template is deleted */
                  /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
                  calculationData?.sourceTemplate?.title ?? 'deleted template'
                }
                contentWidth={calculationData?.description ? '25rem' : undefined}
              />
              {calculationData?.description && (
                <CalculationTitleItem
                  label="Description"
                  labelWidth="6rem"
                  content={calculationData.description}
                  contentWidth="23.5rem"
                />
              )}
            </RowCentered>
          </Column>
          <Dropdown
            trigger={dropdownTrigger}
            menu={calculationOptionDropdownItems}
            align={dropdownAlign}
          >
            <div>
              <MoreIconButton />
            </div>
          </Dropdown>
        </RowCentered>
        <RowCentered $padding={1}>
          <Space>
            <CalculationPotentialOverview />
            {user?.isSuperUser && (
              <PrivateCalculationsIcon
                size="small"
                loading={isLoading}
                tooltip={isPrivate ? `Make public` : `Make private`}
                onClick={togglePrivate}
                value={isPrivate}
              />
            )}
            <PapSyncButton />
            <ProductionCalculationIcon
              size="small"
              loading={isLoading}
              tooltip={
                isProduction
                  ? `Remove "production" flag`
                  : `Mark this calculation as "production", meaning it was really redistributed.`
              }
              onClick={toggleProduction}
              value={isProduction}
            />
            <LoadIntoTemplateAction
              size="small"
              tooltipPosition="left"
              onClick={showLoadIntoTemplateModalHandler}
            />
          </Space>
        </RowCentered>
      </SectionHeaderRowSpaceBetween>
      <SectionHeaderRowSpaceBetweenNoBorder>
        <MenuSubpages
          routingPage={RoutingPage.CALCULATIONS}
          submenuOptions={submenuOptions}
          selectedOption={subpage}
        />
        {subpage === CalculationDetailSubpages.CATEGORIES && <ConfigurationExceptions />}
      </SectionHeaderRowSpaceBetweenNoBorder>
      {openedActionModal === CalculationAction.EDIT && calculationData && (
        <EditCalculationModal
          open={true}
          onCancel={closeOpenedActionModal}
          calculationId={calculationData.id}
        />
      )}
      {openedActionModal === CalculationAction.DELETE && calculationData && (
        <GenericConfirmationModal
          title="Delete calculation"
          message={`Calculation '${calculationData.title}' will be deleted. Continue? `}
          onConfirmed={deleteCalculationHandler}
          onCanceled={closeOpenedActionModal}
        />
      )}

      {showLoadIntoTemplateModal && calculationData && (
        <LoadIntoTemplateModal
          calculationId={calculationData.id}
          calculationTitle={calculationData.title}
          onCanceled={closeLoadIntoTemplateModal}
          onConfirmed={onConfirmed}
        />
      )}
    </>
  );
};

export default CalculationHeader;
