import React, { ReactNode, useMemo } from 'react';
import { computeRemSize, Text } from '@ydistri/ds';
import { SimpleTable, SimpleTableDivider } from '../../../Statistics/components/SimpleTable';
import { translatePapProgressStatus } from '../../pickingResultsLib';
import dayjs from 'dayjs';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import { useGetPickingResultsSummaryQuery } from '../../apiCalculationPickingResults';
import { ColumnsType } from 'antd/es/table';

type OverallStatistics =
  | {
      title: string;
      key: string;
      value: string | number | ReactNode;
      fromNow?: string | number;
    }
  | SimpleTableDivider;

/**
 * Shows the calculation details like when the calculation was
 * computed and when it should be finished.
 * Displayed in the header.
 */
const CalculationDetails: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { data, isFetching: isFetchingStatistics } = useGetPickingResultsSummaryQuery({
    calculationId: templateOrCalculation.id,
  });

  const overallEvaluationsColumns: ColumnsType<OverallStatistics> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: computeRemSize(200),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      align: 'left',
    },
    {
      title: 'From now',
      dataIndex: 'fromNow',
      key: 'fromNow',
      align: 'left',
      render: value => (value ? <Text $size="small">({value})</Text> : null),
    },
  ];

  const tableData: OverallStatistics[] = useMemo(() => {
    const newData: OverallStatistics[] = [];
    if (data) {
      newData.push(
        ...[
          {
            title: 'Status',
            key: 'overallPickingStatus',
            value: (
              <Text $bold={true} $size="large">
                {translatePapProgressStatus(data.pickingStatus)}
              </Text>
            ),
          },
          {
            title: 'Calculation computed on:',
            key: 'calculationCreatedAt',
            value: dayjs(data.calculationCreatedAt ?? '-').format('Do MMMM YYYY'),
            fromNow: dayjs(data.calculationCreatedAt ?? '-').fromNow(),
          },
          {
            title: 'Finish execution until:',
            key: 'finishExecutionUntil',
            value: data.finishExecutionUntil
              ? dayjs(data.finishExecutionUntil).format('Do MMMM YYYY')
              : 'Not available',
            fromNow: data.finishExecutionUntil ? dayjs(data.finishExecutionUntil).fromNow() : '',
          },
        ],
      );
    }

    return newData;
  }, [data]);

  return (
    <SimpleTable<OverallStatistics>
      id="overall-statistics"
      rowKey="key"
      dataSource={tableData}
      columns={overallEvaluationsColumns}
      loading={isFetchingStatistics}
    />
  );
};

export default CalculationDetails;
