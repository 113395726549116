import { Popover } from 'antd';
import { COLORS, Panel, Text } from '@ydistri/ds';
import React from 'react';
import { styled } from 'styled-components';

const OutlierPanel = styled(Panel)`
  background-color: ${COLORS.TABLE_HIGHLIGHT_RED};
  border: 1px solid ${COLORS.DANGER};
  color: ${COLORS.DANGER};
  justify-content: center;
`;

interface OutlierValueProps {
  value: number | string;
}

const OutlierValue: React.FC<OutlierValueProps> = ({ value }) => {
  return (
    <OutlierPanel>
      <Popover content="An exceptionally different value from the rest" trigger="hover">
        <Text $bold={true}>{value}</Text>
      </Popover>
    </OutlierPanel>
  );
};

export default OutlierValue;
